import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  backendUrl: 'https://api.dev.pocketdapp.com',
  firebase: {
    apiKey: 'AIzaSyDDZcYYnJF65J2W_1pBYMTrxwZfk-Gew2I' || '',
    authDomain: 'pocket-4c8ab.firebaseapp.com' || '',
    databaseURL: 'https://pocket-4c8ab-default-rtdb.firebaseio.com' || '',
    projectId: 'pocket-4c8ab' || '',
    storageBucket: 'pocket-4c8ab.firebasestorage.app' || '',
    messagingSenderId: '891702691939' || '',
    appId: '1:891702691939:web:fb7f65eefaa84931bc9056' || '',
    measurementId: 'G-4K9Z5T7Z6E' || '',
  },
};
